<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord" v-if="filters.status == 'C'">Nueva ODC</a-button>
					<!-- <a-button class="btn btn-info ml7" icon="plus" @click="onAddRecordTax" v-if="filters.status == 'C'">Referenciados</a-button> -->
					<!-- <a-button class="btn btn-info ml7" icon="plus" @click="onAddBankCommission" v-if="filters.status == 'C'">Comisión bancaria</a-button> -->
					<!-- <a-button class="btn btn-info ml7" icon="plus" @click="onRefund" v-if="filters.status == 'C'">Devolución a Cliente</a-button> -->
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-9">
					<a-radio-group :value="filters.status" @change="handleStatusChange" style="padding: 10px 20px; background-color: #dedede">
						<a-radio-button value="C"> Cotización </a-radio-button>
						<a-radio-button value="0"> Por autorizar </a-radio-button>
						<a-radio-button value="1"> Por pagar </a-radio-button>
						<a-radio-button value="2"> Pagadas </a-radio-button>
						<a-radio-button value="3"> Facturadas </a-radio-button>
						<a-radio-button value="4"> Validadas </a-radio-button>
						<a-radio-button value="6"> En sucursal </a-radio-button>
					</a-radio-group>
				</div>
				<div class="col-md-3 text-right">
					<a-radio-group :value="filters.status" @change="handleStatusChange" style="padding: 10px 20px; background-color: #dedede">
						<a-radio-button value="Todos"> Todas las ODC </a-radio-button>
						<a-radio-button value="-1"> Rechazadas </a-radio-button>
					</a-radio-group>
				</div>
			</div>
			<div class="row pt20">
				<div class="col-md-4">
					<b># de ODC</b>
					<a-input v-model="filters.ticket" v-mask="'####'" v-on:keyup.enter="searchByTicket" allowClear @change="onResetTicket" />
				</div>
			</div>
			<div class="row pt20" v-if="Number(filters.status) == 2">
				<div class="col-md-12">
					<div class="card bg-primary border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Total</div>
								<div class="font-size-18">{{ numeral(totalPurchaseOrders.totalCash + totalPurchaseOrders.totalDigital + totalPurchaseOrders.totalMercadoPago).format('$0,0.00') }}</div>
								<div class="text-right" style="position: absolute; bottom: 10px; right: 10px">
									<a-range-picker @change="initModule" v-model="filters.defaultDay" :default-value="filters.defaultDay" :format="dateFormat" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card bg-success border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Efectivo</div>
								<div class="font-size-18">{{ numeral(totalPurchaseOrders.totalCash).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card bg-info border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Transferencias</div>
								<div class="font-size-18">{{ numeral(totalPurchaseOrders.totalDigital).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card bg-warning border-0">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">Mercadopago</div>
								<div class="font-size-18">{{ numeral(totalPurchaseOrders.totalMercadoPago).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" :class="Number(filters.status) == 2 ? '' : 'pt20'" v-if="filters.status == 'R'">
				<div class="col-md-12">
					<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="getColumns" :dataSource="purchaseOrdersList" rowKey="id" bordered class="pt20">
						<div slot="description" slot-scope="record">
							{{ `${record.quantity}` }}x {{ record.measurement_unit == 'piece' ? 'Pieza' : record.measurement_unit == 'liter' ? 'Litro' : 'Servicio' }} - {{ record.description }}
						</div>
						<div slot="service_order" slot-scope="record">
							<a-tag color="purple" v-if="record.service_order > 0">ODS {{ record.service_order }}</a-tag>
							<span v-else>- N/A -</span>
						</div>
						<div slot="action" slot-scope="record">
							<button class="btn btn-danger ant-btn-sm ant-btn-icon-only ml7" v-if="userPermition" @click="onDelete(record.id)">
								<a-icon type="delete" />
							</button>
							<span v-else>- N/A -</span>
						</div>
					</a-table>
				</div>
				<div class="col-md-12 text-right">
					<a-button class="btn btn-primary ml7" icon="plus" @click="onAuthorize" v-if="selectedRowKeys.length">Generar ODC de {{ selectedRowKeys.length }} elementos</a-button>
				</div>
			</div>
			<div class="row" :class="Number(filters.status) == 2 ? '' : 'pt20'" v-else>
				<div class="col-md-12">
					<a-table :columns="getColumns" :dataSource="purchaseOrdersList" rowKey="id" bordered>
						<div slot="payment_type" slot-scope="record">
							<div>
								{{ record.tradename | replaceIfLogicalFalse(record.business_name) }}
							</div>
							<div v-if="record.is_tax">
								{{ record.elements[0].description.toUpperCase() }}
								<br />
								<a-tag color="blue">Digital</a-tag>
							</div>
							<div v-else-if="record.payment_method_data">
								<a-tag color="green" v-if="JSON.parse(record.payment_method_data).payment_method == 1"> Efectivo </a-tag>
								<a-tag color="blue" v-else>Digital</a-tag>
							</div>
							<div v-else>- No establecido -</div>
							<a-tag color="pink" v-if="record.sat_payment_method == 'NEXT'"> Por pagar: {{ moment(record.credit_payment_date).format('DD-MM-YYYY') }} </a-tag>
							<div v-if="record.sat_payment_method == 'PPD'">
								<div v-for="(paymentDate, ppdIndex) in record.ppd_payments" :key="ppdIndex">
									<a-tag color="pink" v-if="!paymentDate.paymentReceipt"> Por pagar: {{ moment(paymentDate.paymentDate).format('DD-MM-YYYY') }} </a-tag>
								</div>
							</div>
						</div>
						<div slot="order_date" slot-scope="record">
							<div>{{ moment(record.created_at).format('DD-MM-YYYY') }}</div>
							<div>{{ record.fullName }}</div>
						</div>
						<div slot="order" slot-scope="record">
							<span style="text-decoration: underline" class="pointer" @click="onOpenModal(record.elements)">{{ `${record.elements.length} ${record.elements.length == 1 ? `elemento
															solicitado` : `elementos solicitados`}` }}
							</span>
						</div>
						<div slot="amount" slot-scope="record">
							{{ numeral(record.total).format('$0,0.00') }}
						</div>
						<div slot="action" slot-scope="record">
							<a-button class="btn btn-success" icon="check" v-if="userPermition && record.status == 0 && record.blocked == true" @click="onChangeStatus(record.id)" />
							<a-button class="ml7 btn" :class="record.total ? 'btn-warning' : 'btn-info'" :icon="record.total ? 'eye' : 'edit'" @click="onEditRecord(record.id)" />
							<a-button class="ml7 btn btn-danger" icon="close" v-if="record.status == 0" @click="onDisableRecord(record.id)" />
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal width="30%" :visible="orderModal" :footer="null" title="Detalle de pedido" :closable="true" @cancel="onCloseModal">
			<div v-for="(element, index) in elements" :key="index">
				{{ element.quantity }}x <span v-if="element.measurement_unit">{{ element.measurement_unit == 'liter' ? 'litro' : element.measurement_unit == 'piece' ? 'pieza' : 'servicio' }}</span> -
				{{ element.description }}
				<div v-if="element.service_order > 0">
					<a-tag color="purple">ODS {{ element.service_order }}</a-tag>
				</div>
				<div v-if="element.sku">
					<small>{{ element.sku }}</small>
				</div>
			</div>
		</a-modal>
		<a-modal width="50%" :visible="modalTaxVisible" title="Pago referenciado" :closable="true" @cancel="onCloseModalTax">
			<template slot="footer">
				<a-button key="back" @click="onCloseModalTax" :loading="spinnerLoader"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreateTax" :loading="spinnerLoader"> Guardar </a-button>
			</template>
			<taxesODC ref="modalTax" :visible="modalTaxVisible" @close="onCloseModalTax" />
		</a-modal>
		<a-modal width="50%" :visible="modalBankCommissionVisible" title="Registro de comisiones bancarias" :closable="true" @cancel="onCloseModalBankCommission">
			<template slot="footer">
				<a-button key="back" @click="onCloseModalBankCommission" :loading="spinnerLoader"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreateBankCommission" :loading="spinnerLoader"> Guardar </a-button>
			</template>
			<bankCommissionODC ref="modalBankCommission" :visible="modalBankCommissionVisible" @close="onCloseModalBankCommission" />
		</a-modal>
		<a-modal width="50%" :visible="modalRefundVisible" title="Devolución de pago" :closable="true" @cancel="onCloseModalRefundVisible">
			<template slot="footer">
				<a-button key="back" @click="onCloseModalRefundVisible" :loading="spinnerLoader"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreateRefund" :loading="spinnerLoader"> Guardar </a-button>
			</template>
			<refundComponent ref="modalRefund" @closeModal="onCloseModalRefundVisible" />
		</a-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import { mask } from 'vue-the-mask'
import taxesODC from '@/components/taxesODC'
import bankCommissionODC from '@/components/bankCommissionODC'
import refundComponent from '@/components/refundComponent'

const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'PurchaseOrders',
	directives: {
		mask,
	},
	components: {
		taxesODC,
		bankCommissionODC,
		refundComponent,
	},
	data() {
		return {
			columns: {
				P: [
					{
						title: '# ODC',
						dataIndex: 'id',
						key: 'id',
						align: 'center',
					},
					{
						title: 'Fecha / Registro',
						scopedSlots: { customRender: 'order_date' },
						align: 'center',
					},
					{
						title: 'Tipo de pago',
						scopedSlots: { customRender: 'payment_type' },
						align: 'center',
					},
					{
						title: 'Pedido',
						scopedSlots: { customRender: 'order' },
						align: 'center',
					},
					{
						title: 'Total',
						scopedSlots: { customRender: 'amount' },
						align: 'center',
					},
					{
						title: 'Acciones',
						scopedSlots: { customRender: 'action' },
						align: 'center',
					},
				],
				R: [
					{
						title: 'Solicitud',
						scopedSlots: { customRender: 'description' },
						align: 'center',
					},
					{
						title: 'Orden de servicio',
						scopedSlots: { customRender: 'service_order' },
						align: 'center',
					},
					{
						title: 'Acciones',
						scopedSlots: { customRender: 'action' },
						align: 'center',
					},
				],
			},
			dateFormat,
			orderModal: false,
			elements: [],
			selectedRowKeys: [],
			modalTaxVisible: false,
			modalBankCommissionVisible: false,
			modalRefundVisible: false,
		}
	},
	computed: {
		...mapGetters('purchaseOrders', ['purchaseOrdersList', 'filters', 'totalPurchaseOrders']),
		...mapGetters('user', ['user', 'isUserAdmin']),
		userPermition() {
			// zem - 17
			// pozos - 11
			// ismael - 1
			// armando - 8
			// jesus - 41
			let allowedUserPermition = [1, 11, 8, 17, 41]
			// return !!allowedUserPermition.includes(17)
			return !!allowedUserPermition.includes(this.user.user_id)
		},
		isUserAdmin() {
			return !!this.user.is_admin
		},
		spinnerLoaderLabel() {
			return this.$store.state.purchaseOrders.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.purchaseOrders.spinnerLoader
		},
		getColumns() {
			if (this.filters.status == 'R') {
				return this.columns.R
			} else {
				return this.columns.P
			}
		},
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		moment,
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},
		handleStatusChange(e) {
			this.$store.commit('purchaseOrders/SET_STATE', {
				filters: {
					...this.filters,
					status: e.target.value,
				},
			})
			this.initModule()
		},
		initModule() {
			//
			let payload = {
				status: this.filters.status,
			}

			if (Number(this.filters.status) == 2) {
				payload = {
					...payload,
					startDate: moment(this.filters.defaultDay[0]).format(dateFormat),
					endDate: moment(this.filters.defaultDay[1]).format(dateFormat),
				}
			}

			// Añadimos estatus a la búsqueda
			if (this.filters.status == '') {
				payload.status = 'C'
			}

			this.$store.dispatch('purchaseOrders/GET', payload)
		},
		onAddRecord() {
			this.$router.push('/purchase-orders/create')
		},
		onAddRecordBank() {
			//
			console.log('onAddRecordBank')
		},
		onEditRecord(id) {
			this.$store.dispatch('purchaseOrders/SET_LOCAL', id)
		},
		onChangeStatus(id) {
			let total = this.purchaseOrdersList.find((e) => e.id == id).total

			if (!total) {
				//
				Swal.fire({
					title: 'Atención',
					html: `No se puede cambiar el estatus de la ODC sin establecer el monto total de la compra.`,
					icon: 'warning',
					confirmButtonText: 'Ok',
					cancelButtonText: 'Cancelar',
				})
			} else {
				Swal.fire({
					title: 'Atención',
					text: '¿Deseas autorizar la ODC?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, continuar',
					cancelButtonText: 'Cancelar',
				}).then((result) => {
					if (result.isConfirmed) {
						this.$store.dispatch('purchaseOrders/UPDATE_STATUS', {
							id,
							status: 1,
						})
					}
				})
			}
		},
		onDisableRecord(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas rechazar la ODC?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let total = this.purchaseOrdersList.find((e) => e.id == id).total
					let inputOptions = {}

					if (!total) {
						inputOptions = {
							3: 'INTERNO - Cambio/error de proveedor/método de pago',
							4: 'INTERNO - Cambio/error de unidad de medida',
							5: 'INTERNO - Cambio/error de cantidad',
							6: 'INTERNO - Cambio/error de total',
							7: 'INTERNO - Cambio/error de descripción',
						}
					} else {
						inputOptions = {
							1: 'PROVEEDOR - Cambio de precio/cotización',
							2: 'PROVEEDOR - Existencia/capacidad de entrega',
							3: 'INTERNO - Cambio/error de proveedor/método de pago',
							4: 'INTERNO - Cambio/error de unidad de medida',
							5: 'INTERNO - Cambio/error de cantidad',
							6: 'INTERNO - Cambio/error de total',
							7: 'INTERNO - Cambio/error de descripción',
						}
					}

					Swal.fire({
						title: 'Selecciona el motivo de rechazo',
						input: 'select',
						inputOptions,
						showCancelButton: true,
						confirmButtonText: 'Continuar',
						cancelButtonText: 'Cancelar',
						inputPlaceholder: 'Selecciona',
						inputValidator: (value) => {
							return new Promise((resolve) => {
								if (!isNaN(value)) {
									value = Number(value)
									if (!value || value < 0) {
										resolve('Es necesario elegir un motivo')
									}
								} else {
									resolve('Es necesario elegir un motivo')
								}
								resolve()
							})
						},
					}).then((resultReason) => {
						if (resultReason.value != '' && resultReason.isConfirmed) {
							//
							let reject_reason_id = Number(resultReason.value)
							let status = reject_reason_id == 1 || reject_reason_id == 2 ? 0 : -1

							let payload = {
								id,
								status,
								reject_reason_id,
								elements: this.purchaseOrdersList.find((e) => e.id == id).elements.map((e) => e.ie_id),
							}
							this.$store.dispatch('purchaseOrders/UPDATE_STATUS', payload)
						}
					})
				}
			})
		},
		authorizingUser(payload) {
			let response = ''
			let approved_by = JSON.parse(payload)
			response = `${approved_by.name} ${approved_by.first_lastname} ${approved_by.second_lastname || ''}`
			return response
		},
		resetVariables() {
			this.$store.dispatch('purchaseOrders/RESET_FILTERS')
			this.initModule()
		},
		onOpenModal(elements) {
			this.elements = _.cloneDeep(elements)
			this.orderModal = true
		},
		onCloseModal() {
			this.elements = []
			this.orderModal = false
		},
		onAuthorize() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas generar ODC a partir de los productos seleccionados?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				//
				if (result.isConfirmed) {
					let elements = this.purchaseOrdersList.map((e) => {
						if (this.selectedRowKeys.includes(e.id)) {
							return e
						}
					})
					let filtered = elements.filter((x) => {
						return x !== undefined
					})
					await this.$store.dispatch('impressment/CONFIRM', { elements: filtered, fromPurchaseOrders: true })
					this.selectedRowKeys = []
					this.initModule()
				}
			})
		},
		onDelete(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar el registro?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				//
				if (result.isConfirmed) {
					this.$store.dispatch('impressment/DELETE', { id, fromPurchaseOrders: true })
				}
			})
		},
		onAddRecordTax() {
			this.modalTaxVisible = true
		},
		onCreateTax() {
			//
			this.$refs.modalTax.onSaveRecord()
		},
		onCloseModalTax() {
			this.modalTaxVisible = false
		},
		onAddBankCommission() {
			this.modalBankCommissionVisible = true
		},
		onRefund() {
			this.modalRefundVisible = true
		},
		onCloseModalRefundVisible() {
			this.modalRefundVisible = false
			this.$refs.modalRefund.resetVariables()
		},
		onCreateBankCommission() {
			this.$refs.modalBankCommission.onSaveRecord()
		},
		onCreateRefund() {
			this.$refs.modalRefund.onSaveRecord()
		},
		onCloseModalBankCommission() {
			this.modalBankCommissionVisible = false
		},
		searchByTicket() {
			let payload = {
				ticket: this.filters.ticket,
			}
			this.$store.dispatch('purchaseOrders/GET', payload)
		},
		onResetTicket(e) {
			if (e.target.value == '') {
				this.initModule()
			}
		},
	},
}
</script>

